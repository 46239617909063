<template>
  <div id="ProducerPublicList">
    <QsCard :qlik-ids="['qJFaW']" card-style="compact" />
    <QsCard :qlik-ids="['pAkDs']" />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";

export default { components: { QsCard } };
</script>

<style scoped>
#ProducerPublicList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 45px repeat(1, 600px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
